import css from 'styled-jsx/css';
import colors from '../../constants/colors';

export default css`
*{
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.disabled{
  display: none;
}
.overlay-bar .utility-menu .utility-inner{
  border: none;
}
.nav-container {
  height: 100px;
}
.top-bar .logo{
  max-width: 275px;
}
.btn-primary.btn-filled{
  background: ${colors.landingHeaderText};
}
.btn-primary{
  border-color:${colors.landingHeaderText};
}
.btn-white{
  border-color: #fff;
}
.btn-white-withborder {
  background: white;
  color: red;
}
.btn-white:hover, .btn-white:focus {
  background: #fff;
  color: #333333;
}
btn-primary:hover, btn-primary:focus{
  background: #fff;
  color: ${colors.landingHeaderText};
}
.text-link{
  padding: 0px 10px;
  color: ${colors.landingHeaderText};
}

.languageWrapper {
  float: right;
  width: 60px;
}
.language {
  top: 5px;
  width: 30px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400 !important;
  color: ${colors.landingHeaderText};
  margin-left: 8px;
}
.dropDownLanguage {
  position: absolute;
}
.languageItem {
  text-align: center;
  padding-top: 30px;
}
.languageWrapperHamburger {
  display: flex;
  justify-content: space-between;
  height: 22px;
}
.languageHamburger {
  padding: 5px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400 !important;
  color: #fff;
}
.hamburger_menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  padding: 5px 15px;
  background: rgba(0,0,0,0.2);
}
.hamburger_menu .logo{
  height: 40px
}
.hamburger_icon {
  position: relative;
  min-width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
}
.hamburger_content {
  display: none;
  flex-direction: column;
  background: rgba(0,0,0,0.2);
  position: absolute;
  min-width: 125px;
  top: 50px;
  right:0;
  z-index: 2;
  box-sizing: border-box;
  padding: 10px;
  color: #fff;
}
.hamburger_content a {
  color: #fff;
  text-align: center;
}
.hamburger_content .no_button {
  background: none;
  border: 0px;
}
.hamburger_menu:hover .hamburger_content{
  display: flex;
  flex-direction: column;
}
.btn {
  min-width: 60px;
  padding-left: 18px;
  padding-right: 18px;
  margin-left: 8px;
  margin-right: 0px;
  text-align: center;
    vertical-align: middle;
    cursor: pointer;
}
.btn-primary {
  border-radius: 25px;
  display: inline-block;
  line-height: 1;
  border: 2px solid ${colors.landingHeaderText};
  font-family: "Raleway", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.btn-filled{
  color: white;
}
a:hover{
  text-decoration: none;
}
.btn-xs {
  padding: 8px 24px;
  font-size: 10px;
}
.menu_option {
  margin-bottom: 5px;
}
@media (min-width:768px) {
  .hamburger_menu{
    display: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px){
.overlay-bar {
    background: rgba(255, 255, 255, 0.0);
}
}
`;
