import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tappable from 'react-tappable';
import i18next from 'i18next';
import styles from './styles';
import { Link } from '../../../routes';
import SVGIcon from '../../components/SVGIcon/SVGIcon';
import { HamburgerIcon } from '../../constants/icons';
import DropDownItem from '../../components/DropDownItem/DropDownItem';
import phoneCodes from './phone_codes.json';
import translate from 'translate';
import { connect } from 'react-redux';
import appActionTypes from "../../types/app";
import appModalTypes from '../../constants/authModalTypes';
import { resetUser } from '../../actions/app';
import * as config from '../../config';

translate.engine = 'google';
translate.key = 'AIzaSyBye_LLTmZyqpo2VJro10oOW47EfzxoFCM';

class LandingNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageDropdown: false,
      chosenLanguage: {
        id: this.props.t('browser_language'),
        language: this.props.t('browser_languageUpperCase'),
      },
      languages: [
        {
          id: 'de',
          language: 'DE',
        },
        {
          id: 'en',
          language: 'EN',
        },
        {
          id: 'fr',
          language: 'FR',
        },
        {
          id: 'it',
          language: 'IT',
        },
      ],
      countries: [],
    };

    this.setChosenLanguage = this.setChosenLanguage.bind(this);
    this.toggleDropdownLanguage = this.toggleDropdownLanguage.bind(this);
    this.translateCountryList = this.translateCountryList.bind(this);
    this.logout = this.logout.bind(this);
  }

  setChosenLanguage(id, language) {
    const languageNew = Object.assign({}, this.state.chosenLanguage);
    languageNew.id = id;
    languageNew.language = language;
    this.setState({ chosenLanguage: languageNew });
    this.toggleDropdownLanguage();
    i18next.changeLanguage(id);
    this.translateCountryList();
    location.reload();
  }

  translateCountryList() {
    this.setState({
      countries: [],
    });
    phoneCodes.map(entry => {
      translate(entry.name, this.props.t('browser_language')).then((result) => {
        if(result == 'Ich rannte'){
          result = 'Iran';
        }
        this.state.countries.push({
          name: result,
          dial_code: entry.dial_code,
        })
      })
    });
  }

  toggleDropdownLanguage() {
    this.setState({
      languageDropdown: !this.state.languageDropdown
    });
  }

  logout() {
    localStorage.clear();
    this.props.resetUser();
  }

  render() {
    const { t, user } = this.props;

    return (
      <>
      <div className={'nav-container nav-container'}>
        <nav className={'top-bar topbar'}>
          <div className={'container'}>
            <div className={'row utility-menu'}>
              <div className={'col-sm-12'}>
                <div className={'utility-inner utility-inner clearfix'}>
                  {this.props.logo &&
                    <Link route="index" href="/#">
                      <img className={'logo'} alt="Logo" src={this.props.logo} />
                    </Link>
                  }
                  <div className={'pull-right'}>
                    <a className={'text-link'} href="http://account.itherapeut.ch">
                      {t('menu_serviceProvider')}
                    </a>
                    <Link route="help" href="/#">
                      <a className={'text-link'} href="/#">
                        {t('menu_help')}
                      </a>
                    </Link>

                    <a className={`${user ? 'disabled' : 'btn btn-primary btn-white-withborder btn-xs'}`} onClick={() => this.props.authModalChange(appModalTypes.signIn)}>
                      {t('label_login')}
                    </a>
                    <a className={`${user ? 'disabled' : 'btn btn-primary btn-filled btn-xs'}`} onClick={() => this.props.authModalChange(appModalTypes.signUp)}>
                      {t('label_signup')}
                    </a>
                    <Link route="dashboard/dashboard" href="/#">
                      <a className={`${user ? 'btn btn-primary btn-filled btn-xs' : 'disabled'}`}>
                        {t('menu_dashboard')}
                      </a>
                    </Link>
                    <a className={`${user ? 'btn btn-primary btn-white-withborder btn-xs' : 'disabled'}`} onClick={() => this.logout()}>
                      {t('label_logout')}
                    </a>
                    {
                      process.env.NODE_ENV === 'development' && (
                        <div className={'languageWrapper'}>
                          <Tappable onTap={this.toggleDropdownLanguage}>
                            <span className={'language language'}>{this.state.chosenLanguage.language}</span>
                          </Tappable>
                          { this.state.languageDropdown &&
                          <div className={'dropDownLanguage'}>
                            {
                              this.state.languages.map(languageEntry => (
                                languageEntry.id !== this.state.chosenLanguage.id &&
                                <div className={'languageItem'}>
                                  <DropDownItem
                                    dropDownType="language"
                                    id={languageEntry.id}
                                    language={languageEntry.language}
                                    setChosenLanguage={this.setChosenLanguage}
                                  />
                                </div>
                              ))
                            }
                          </div>
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div className={'hamburger_menu'}>
          {this.props.logo &&
            <Link route="index" href="/#">
              <img className={'logo'} alt="Logo" src={this.props.logo} />
            </Link>
          }
          <div className={'hamburger_icon'}>
            <SVGIcon icon={HamburgerIcon} />
          </div>
          <div className={'hamburger_content'}>
            <a className="menu_option truncate" href="http://account.itherapeut.ch">
              <span>{t('menu_serviceProvider')}</span>
            </a>
            <a className="menu_option truncate" href={config.faqUrl}>
              <span>{t('menu_help')}</span>
            </a>
            <button className={`${user ? 'disabled' : 'menu_option truncate no_button'}`} onClick={() => this.props.authModalChange(appModalTypes.signIn)}>
              <span>{t('label_login')}</span>
            </button>
            <button className={`${user ? 'disabled' : 'menu_option highlight truncate no_button'}`} onClick={() => this.props.authModalChange(appModalTypes.signUp)}>
              <span>{t('label_signup')}</span>
            </button>
            <Link route="dashboard/dashboard" href="/#">
              <button className={`${this.state.isLoggedIn ? 'menu_option highlight truncate no_button' : 'disabled'}`}>
                <span id="menu_white">{this.props.t('menu_dashboard')}</span>
              </button>
            </Link>
            <button className={`${user ? 'menu_option truncate no_button' : 'disabled'}`} onClick={() => this.logout()}>
              <span>{t('label_logout')}</span>
            </button>
            <div className="languageWrapperHamburger">
              <Tappable onTap={() => this.setChosenLanguage(this.state.languages[0].id, this.state.languages[0].language)}>
                <span className="languageHamburger">{this.state.languages[0].language}</span>
              </Tappable>
              <Tappable onTap={() => this.setChosenLanguage(this.state.languages[1].id, this.state.languages[1].language)}>
                <span className="languageHamburger">{this.state.languages[1].language}</span>
              </Tappable>
              <Tappable onTap={() => this.setChosenLanguage(this.state.languages[2].id, this.state.languages[2].language)}>
                <span className="languageHamburger">{this.state.languages[2].language}</span>
              </Tappable>
              <Tappable onTap={() => this.setChosenLanguage(this.state.languages[3].id, this.state.languages[3].language)}>
                <span className="languageHamburger">{this.state.languages[3].language}</span>
              </Tappable>
            </div>
          </div>
        </div>

        <style jsx>{styles}</style>
      </div>
      </>
    );
  }
}

LandingNav.defaultProps = {
  title: null,
  logo: null,
  overlay: false,
};

LandingNav.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  logo: PropTypes.string,
  overlay: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    user: state.app.user
  }
};

const mapDispatchToProps = (dispatch) => ({
  authModalChange: (name) => dispatch({ type: appActionTypes.AUTH_MODAL_CHANGE, payload: { name } }),
  resetUser: resetUser(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingNav);
